export const environment = {
  firebase: {
    apiKey: "AIzaSyAz3plMXD6q8nM0GS60isphMMBYr7AU3vw",
    authDomain: "vogo-bus-staging.firebaseapp.com",
    projectId: "vogo-bus-staging",
    storageBucket: "vogo-bus-staging.firebasestorage.app",
    messagingSenderId: "25251346945",
    appId: "1:25251346945:web:49476bc5192f3dd1b85e00",
    measurementId: "G-VQDZNP9X53"
  },
  VAPIDKEY: 'BJespuHCGXaRO4YdwQtpunBK9h62vqQEnwHlJE0cokI68OlRV73X3JMKaNvWh5udVdN85HG1DPxpBEmHJ28C2As',
  COMPUTED_ROUTE_MATRIX: 'https://routes.googleapis.com/distanceMatrix/v2:computeRouteMatrix',
  COMPUTED_ROUTE: 'https://routes.googleapis.com/directions/v2:computeRoutes',
  GOOGLE_SCRIPT: 'https://maps.googleapis.com/maps/api/js',
  google_api_key: 'AIzaSyBWLWsLeLXb1X7rgxwhBaxl6UnCBykjl60',
  routes_api_key: 'AIzaSyDeJ5xM143_LxsyxSacy36EVeBALiaRbdY',
  production: true,
  version: '0.0.1 (beta)',
  graphql_endpoint: 'https://staging-graph.vogo.storexweb.team/graphql',
  url: 'https://staging-files.vogo.storexweb.team',
  places_key: '',
  skip_auth: false,
  STRIP_KEY:"pk_test_s6cToNHgpsPrcwPPnHu5Zevq00VN5oUuax",
  TRACKING_SOCKET_URL: "https://staging-socket.vogo.storexweb.team/tracking",
  driverAndroidApp:'intent://profile/4#Intent;package=com.facebook.katana;scheme=fb;end',
  driverIOSApp:'intent://profile/4#Intent;package=com.facebook.katana;scheme=fb;end',
  CHAT_SOCKET_URL: "https://staging-socket.vogo.storexweb.team/support-chat",
  REPORT_URL: "https://staging-report.vogo.storexweb.team/download-report"
};
